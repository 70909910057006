import { useEffect, useRef, useState } from 'react';
import routes from 'routes';
import { Col, Button, Form, Card, CardBody, FormGroup, Input, Row } from 'reactstrap';
import Select from 'react-select';
import Switch from 'react-bootstrap-switch';
import { useNavigate } from 'react-router-dom';
import { ENV_CONFIG } from 'config/environment';
import { getIsSuper, has_permission } from 'config/permissions';
import Loader from 'components/Loader';
import Sidebar from 'components/Sidebar';
import { SlateInput } from 'components/SlateInput';
import { showToast } from 'components/Toast';
import { SlateNode } from 'components/SlateInput/types';
import AdminNavbar from 'components/Navbars/AdminNavbar';
import { convertToHTML } from 'components/SlateInput/utils';
import { websiteWithLines } from 'helpers/regexp';
import { useAppDispatch, useAppSelector } from 'helpers/hooks';
import { transformToUrl, wait, getUrlParams } from 'helpers/utils';
import { getPagesState } from 'selectors/pages';
import { getRecipientsState } from 'selectors/recipients';
import { getAccessToken, getClientID } from 'services/storage';
import { IPage } from 'store/pages/types';
import { getRecipients } from 'store/recipients/actions';
import { getPages, createPage, updatePage, uploadFile, getAllPages } from 'store/pages/actions';
import Icon, { IconNames } from 'components/Icon';
import colors from 'constants/colors';

interface IRecipient {
  value: string;
  label: string;
}
const PagesDetail = () => {
  const mainPanel = useRef<any>();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [isPageDuplicated, setIsPageDuplicated] = useState(false);
  const [editPageSelected, setEditPageSelected] = useState<IPage[]>();
  const [tagName, setTagName] = useState('');
  const [tagNameState, setTagNameState] = useState('');
  const [checkingTagName, setCheckingTagName] = useState(false);
  const [tagNameInUse, setTagNameInUse] = useState(false);
  const [queryString, setQueryString] = useState('');
  const [queryStringState, setQueryStringState] = useState('');
  const [checkingWebsite, setCheckingWebsite] = useState(false);
  const [websiteInUse, setWebsiteInUse] = useState(false);
  const [tagDescription, setTagDescription] = useState('');
  const [tagDescriptionState, setTagDescriptionState] = useState('');
  const [tempDonationAmount, setTempDonationAmount] = useState<number>(0);
  const [donationAmounts, setDonationAmounts] = useState([10, 25, 50, 100, 250]);
  const [recipientsSelected, setRecipientsSelected] = useState(Array<IRecipient>());
  const [recipients, setRecipients] = useState(Array<IRecipient>());
  const [isUpdatingRecipients, setIsUpdatingRecipients] = useState(false);
  const [tagCTA, setTagCTA] = useState('');
  const [bgColor, setBgColor] = useState<string | null>(null);
  const [textColor, setTextColor] = useState<string | null>(null);
  const [impactScoreVisibility, setImpactScoreVisibility] = useState(false);
  const [selectedFile, setSelectedFile] = useState<File | null>();
  const [preview, setPreview] = useState<string | null>();
  const [pageTypeView, setPageTypeView] = useState('LIST');

  const { recipientsState, pagesState } = useAppSelector(state => ({
    recipientsState: getRecipientsState(state),
    pagesState: getPagesState(state),
  }));

  useEffect(() => {
    if (getClientID() && getAccessToken()) {
      dispatch(getPages(getClientID() || ''));
      dispatch(getAllPages());
    }
  }, []);

  useEffect(() => {
    if (!pagesState.isLoading) {
      if (pagesState.data?.pageCreated) {
        showToast(
          `Success! Your page has been ${editPageSelected ? 'updated' : 'created'}`,
          'success'
        );
        if (selectedFile) {
          const customName = editPageSelected
            ? `tag_${editPageSelected[0].tag.id}.png`
            : `tag_${pagesState.data.pageCreated.tag_id}.png`;
          const renamedFile = new File([selectedFile], customName, { type: selectedFile.type });
          dispatch(uploadFile(renamedFile));
        }
        wait(2000).then(() => navigate('/pages'));
      }
      if (pagesState.data?.pages?.length > 0 && !editPageSelected && getUrlParams()?.tagId) {
        const selected = pagesState.data.pages.filter(
          page => page.tag.id === parseInt(getUrlParams().tagId || '')
        );
        if (selected.length === 0) navigate('/pages');
        else setEditPageSelected(selected);
        if (getUrlParams().copy !== null) setIsPageDuplicated(true);
      }
    }
  }, [pagesState]);

  useEffect(() => {
    if (editPageSelected) {
      setTagName(
        isPageDuplicated ? `${editPageSelected[0].tag.value} Copy` : editPageSelected[0].tag.value
      );
      setQueryString(
        isPageDuplicated
          ? `${editPageSelected[0].tag.query_str}-copy`
          : editPageSelected[0].tag.query_str
      );
      setTagDescription(editPageSelected[0].tag.desc);
      setDonationAmounts(editPageSelected[0].tag.donation_amounts);
      setTagCTA(editPageSelected[0].tag.tag_cta);
      setBgColor(editPageSelected[0].tag.page_misc?.page_background);
      setTextColor(editPageSelected[0].tag.page_misc?.page_color_text);
      setImpactScoreVisibility(editPageSelected[0].tag.impact_score_visibility);
      setPageTypeView(editPageSelected[0].tag.page_type);
      setPreview(
        `${ENV_CONFIG().CLOUD_STORAGE.URL}/${ENV_CONFIG().CLOUD_STORAGE.TAGS}/tag_${editPageSelected[0].tag.id}.png`
      );
      setTagNameState('has-success');
      setQueryStringState('has-success');
      setTagDescriptionState('has-success');
      setIsUpdatingRecipients(true);
      dispatch(getRecipients({ recipient_ids: editPageSelected[0].recipient_ids }));
    }
  }, [editPageSelected]);

  let timeoutId;
  const handleSearchRecipients = (search_str: string) => {
    if (search_str.length > 0) {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }

      timeoutId = setTimeout(function () {
        dispatch(getRecipients({ search_str }));
      }, 500);
    }
  };

  useEffect(() => {
    if (!pagesState.isLoading && pagesState.allPages) {
      if (checkingWebsite) {
        setCheckingWebsite(false);
        if (
          pagesState.allPages?.pages?.some(
            item => item.tag.query_str.toLowerCase() === queryString.toLowerCase()
          )
        ) {
          setQueryStringState('has-danger');
          setWebsiteInUse(true);
        } else setWebsiteInUse(false);
      }
      if (checkingTagName) {
        if (editPageSelected && editPageSelected[0].tag.value === tagName) {
          setTagNameInUse(false);
        } else {
          const isTagNameInUse = pagesState.allPages?.pages?.some(
            item => item.tag.value.toLowerCase() === tagName.toLowerCase()
          );

          if (isTagNameInUse) {
            setTagNameState('has-danger');
            setTagNameInUse(true);
          } else {
            setTagNameInUse(false);
          }
        }
        setCheckingTagName(false);
      }
    }
  }, [checkingWebsite, checkingTagName]);

  useEffect(() => {
    if (!recipientsState.isLoading && recipientsState.data) {
      const arr = Array<IRecipient>();
      recipientsState.data?.recipients?.forEach(rec => {
        arr.push({ value: rec.recipient_id, label: rec.recipient_name });
      });
      if (isUpdatingRecipients) {
        setIsUpdatingRecipients(false);
        setRecipientsSelected(arr);
      } else setRecipients(arr);
    }
  }, [recipientsState]);

  const handleSubmit = () => {
    if (validForm) {
      const body = {
        tag: {
          value: tagName,
          query_str: queryString.toLowerCase(),
          desc: tagDescription,
          donation_amounts: donationAmounts,
          client_id: getClientID() || '',
          tag_cta: tagCTA,
          page_misc: {
            page_background: bgColor,
            page_color_text: textColor,
          },
          impact_score_visibility: impactScoreVisibility,
          is_enabled: true,
          page_type: pageTypeView,
        },
        recipient_ids: recipientsSelected?.map(item => item.value) || [],
      };
      if (editPageSelected && !isPageDuplicated) {
        const updateBody = {
          client_id: getClientID() || '',
          page: {
            ...body,
            tag: {
              ...editPageSelected[0].tag,
              ...body.tag,
              id: parseInt(getUrlParams().tagId || ''),
            },
          },
        };
        dispatch(updatePage(updateBody));
      } else dispatch(createPage(body));
    } else {
      showToast('Some required fields are missing', 'error');
    }
  };

  useEffect(() => {
    if (!selectedFile) {
      setPreview('');
      return;
    }

    const objectUrl = URL.createObjectURL(selectedFile);
    setPreview(objectUrl);

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedFile]);

  const onSelectFile = e => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(undefined);
      return;
    }
    const file = e.target.files[0];
    setSelectedFile(file);
  };

  const validForm =
    tagNameState === 'has-success' &&
    queryStringState === 'has-success' &&
    tagDescriptionState === 'has-success' &&
    donationAmounts.length > 0;

  const handleChangeDescription = (nodes: SlateNode[]) => {
    setTagDescriptionState(nodes ? 'has-success' : 'has-danger');
    setTagDescription(convertToHTML(nodes));
  };

  const addDonationAmount = () => {
    if (!donationAmounts.includes(tempDonationAmount)) {
      let amounts = donationAmounts;
      if (tempDonationAmount) amounts = [...donationAmounts, tempDonationAmount];
      setDonationAmounts(amounts.sort((a, b) => a - b));
      setTempDonationAmount(0);
    }
  };

  const removeItemAmount = (amount: number) => {
    setDonationAmounts(prevAmounts => prevAmounts.filter(item => item !== amount));
  };
  const removeItemRecipient = (recipient: IRecipient) => {
    setRecipientsSelected(prevRecipients =>
      prevRecipients.filter(item => item.value !== recipient.value)
    );
  };

  const resetToDefaultColors = () => {
    setBgColor(null);
    setTextColor(null);
  };

  return (
    <>
      {pagesState.isLoading && <Loader type="dots" />}
      <div className="wrapper">
        <Sidebar routes={routes} />
        <div className="main-panel" ref={mainPanel}>
          <AdminNavbar />
          <Row className="filters-row" style={{ padding: '5px 30px' }}>
            <div className="grid-column">
              <span className="oath-h3 inkBlue">
                {editPageSelected && !isPageDuplicated
                  ? 'Edit your Existing Page'
                  : 'Create a New Donation Page'}
              </span>
              <Row style={{ gap: 4 }}>
                <Icon
                  name={IconNames.ChevronLeft}
                  color={colors.white}
                  className="chevron-svg-inkblue"
                />
                <span className="oath-bodysmall inkBlue link-text" onClick={() => navigate(-1)}>
                  Back to All Pages
                </span>
              </Row>
            </div>
            <Row>
              {/* <Button
                className="btn-inkBlue-inverted"
                style={{ width: 120 }}
                onClick={() => console.log('Do nothing')}
              >
                Preview
              </Button> */}
              <Button className="btn-inkBlue" style={{ width: 120 }} onClick={handleSubmit}>
                Save
              </Button>
            </Row>
          </Row>
          {!has_permission('SCREEN_VIEW_PERMISSION', 'R') ? (
            <div className="content">
              <h1 className="title-permission">
                Sorry, you don't have permission to see this screen. Switch the environment to
                staging.
              </h1>
            </div>
          ) : (
            <div className="content-full-width">
              <Form id="RegisterValidation">
                <Card>
                  <CardBody className="card-body-page-detail">
                    <span className="oath-eyebrow inkBlue">Left-Side Panel</span>
                    <FormGroup className={`${tagNameState}`}>
                      <label htmlFor="name-input">Page Name *</label>
                      <Input
                        id="name-input"
                        name="tag_name"
                        type="text"
                        value={tagName}
                        onBlur={() => setCheckingTagName(true)}
                        onChange={e => {
                          setTagNameState(e.target.value ? 'has-success' : 'has-danger');
                          setTagName(e.target.value);
                        }}
                        disabled={!getIsSuper() && editPageSelected && !isPageDuplicated}
                      />
                      {checkingTagName && <Loader type="spinner" width={20} height={20} />}
                      {tagNameState === 'has-danger' ? (
                        tagNameInUse ? (
                          <label className="error">This page name is already being used.</label>
                        ) : (
                          <label className="error">This field is required.</label>
                        )
                      ) : null}
                    </FormGroup>
                    <Row>
                      {/* In the meantime only for super admins */}
                      {getIsSuper() && (
                        <Col md="6" style={{ padding: 0 }}>
                          <FormGroup>
                            <label>Custom Image. Recommended size: 600px x 1400px.</label>
                            <br />
                            <input
                              id="file"
                              name="file"
                              type="file"
                              onChange={onSelectFile}
                              style={{ display: 'none' }}
                            />
                            <label htmlFor="file" className="custom-input-file">
                              Upload Image
                            </label>
                            <br />
                            {preview && (
                              <img
                                style={{ maxHeight: '180px' }}
                                src={preview}
                                onError={() => setPreview('')}
                              />
                            )}
                            <div
                              className="category form-category"
                              style={{ textTransform: 'none' }}
                            >
                              If you have any problems with your custom image / logo on the page,
                              please email partners@oath.vote
                            </div>
                          </FormGroup>
                        </Col>
                      )}
                      <Col md="6" style={{ padding: 0 }}>
                        <Row style={{ gap: 8 }}>
                          <FormGroup className={'grid-column'}>
                            <label htmlFor="bgcolor-input">Background Color</label>
                            <input
                              id="bgcolor-input"
                              name="background_color"
                              type="color"
                              className="input-color"
                              value={bgColor || colors.inkBlue}
                              onChange={e => setBgColor(e.target.value)}
                            />
                            <span
                              className="oath-utility link-text gray"
                              onClick={resetToDefaultColors}
                            >
                              Reset Colors
                            </span>
                          </FormGroup>
                          <FormGroup
                            className={'grid-column'}
                            style={{ alignContent: 'flex-start' }}
                          >
                            <label htmlFor="textcolor-input">Text Color</label>
                            <input
                              id="textcolor-input"
                              name="text_color"
                              type="color"
                              className="input-color"
                              value={textColor || colors.white}
                              onChange={e => setTextColor(e.target.value)}
                            />
                          </FormGroup>
                        </Row>
                      </Col>
                    </Row>
                    {((tagDescription && editPageSelected) || !editPageSelected) && (
                      <FormGroup className={`${tagDescriptionState}`}>
                        <label htmlFor="description-input">Page Description *</label>
                        <SlateInput
                          id="description-input"
                          value={tagDescription}
                          onChange={handleChangeDescription}
                        />
                        {tagDescriptionState === 'has-danger' ? (
                          <label className="error">This field is required.</label>
                        ) : null}
                      </FormGroup>
                    )}
                    <FormGroup className={`${queryStringState}`}>
                      <span className="oath-eyebrow inkBlue">Website URL</span>
                      <Input
                        id="querystring-input"
                        name="query_string"
                        type="text"
                        value={queryString}
                        style={{ textTransform: 'lowercase' }}
                        onBlur={() => setCheckingWebsite(true)}
                        onChange={e => {
                          setQueryStringState(
                            e.target.value &&
                              websiteWithLines.test(e.target.value) &&
                              !e.target.value.endsWith('-')
                              ? 'has-success'
                              : 'has-danger'
                          );
                          setQueryString(transformToUrl(e.target.value));
                        }}
                        disabled={!!editPageSelected && !isPageDuplicated}
                      />
                      {queryStringState === 'has-danger' ? (
                        !websiteWithLines.test(queryString) || queryString.endsWith('-') ? (
                          <label className="error">This field has incorrect characters.</label>
                        ) : websiteInUse ? (
                          <label className="error">This URL is already being used.</label>
                        ) : (
                          <label className="error">This field is required.</label>
                        )
                      ) : null}
                      <div
                        className="category form-category"
                        style={{ textTransform: 'lowercase' }}
                      >
                        {checkingWebsite && <Loader type="spinner" width={20} height={20} />}
                        {`${ENV_CONFIG().PARTNER_PAGE}${queryString}`}
                      </div>
                    </FormGroup>
                    <FormGroup>
                      <span className="oath-eyebrow inkBlue">Recipient Cards</span>
                      <Row>
                        <Col md="6" style={{ padding: 0 }}>
                          <FormGroup tag="fieldset" style={{ margin: 0 }}>
                            <label>Page Type View</label>
                            <Row>
                              <Col md="6" style={{ padding: 0 }}>
                                <FormGroup check>
                                  <Input
                                    name="radio1"
                                    type="radio"
                                    checked={pageTypeView === 'LIST'}
                                    onClick={() => setPageTypeView('LIST')}
                                  />
                                  <label>LIST</label>
                                </FormGroup>
                              </Col>
                              <Col md="6" style={{ padding: 0 }}>
                                <FormGroup check>
                                  <Input
                                    name="radio1"
                                    type="radio"
                                    checked={pageTypeView === 'COMPACT'}
                                    onClick={() => setPageTypeView('COMPACT')}
                                  />
                                  <label>COMPACT</label>
                                </FormGroup>
                              </Col>
                            </Row>
                          </FormGroup>
                        </Col>
                        <Col md="6" style={{ padding: 0 }}>
                          <FormGroup style={{ margin: 0 }}>
                            <label>Impact Score Visibility</label>
                            <br />
                            <Switch
                              value={impactScoreVisibility}
                              onColor="success"
                              onText="ON"
                              offColor="success"
                              offText="OFF"
                              handleWidth={500}
                              onChange={e => setImpactScoreVisibility(e.state.value)}
                            />
                          </FormGroup>
                        </Col>
                      </Row>

                      <label htmlFor="recipients-input">Recipients</label>
                      <Select
                        id="recipients-input"
                        className="react-select success"
                        classNamePrefix="react-select"
                        placeholder="Search"
                        name="recipientsSelected"
                        isMulti
                        isSearchable
                        isClearable={false}
                        value={recipientsSelected}
                        onChange={setRecipientsSelected}
                        onInputChange={handleSearchRecipients}
                        options={recipients}
                      />
                      <Row className="input-item-list">
                        {recipientsSelected?.map(recipient => (
                          <div
                            className="input-item"
                            key={recipient.value}
                            onClick={() => removeItemRecipient(recipient)}
                          >
                            <span className="oath-utility inkBlue" key={recipient.value}>
                              {recipient.label}
                            </span>
                          </div>
                        ))}
                      </Row>
                      <div className="category form-category" style={{ textTransform: 'none' }}>
                        Missing a recipient you’re looking for? Please complete your page creation
                        and email partners@oath.vote. Additional recipients can be added later.
                      </div>
                    </FormGroup>

                    <FormGroup className={`${donationAmounts.length === 0 && 'has-danger'}`}>
                      <label>Selection Donation Amounts*</label>
                      <Input
                        id="donation-amounts"
                        name="donationAmounts"
                        type="number"
                        min={0}
                        value={tempDonationAmount || ''}
                        onBlur={() => setCheckingTagName(true)}
                        onChange={e => setTempDonationAmount(parseInt(e.target.value))}
                        disabled={donationAmounts.length === 10}
                        onKeyDown={e => e.key === 'Enter' && addDonationAmount()}
                      />
                      <Row className="input-item-list">
                        {donationAmounts.map(amount => (
                          <div className="input-item" onClick={() => removeItemAmount(amount)}>
                            <span className="oath-utility inkBlue" key={amount}>
                              ${amount}
                            </span>
                          </div>
                        ))}
                      </Row>
                      {donationAmounts.length === 10 ? (
                        <label className="error">
                          You’ve reached the maximum number of donation amounts!.
                        </label>
                      ) : null}
                    </FormGroup>
                    <span className="oath-eyebrow inkBlue">
                      Additional customization [optional]
                    </span>
                    <FormGroup>
                      <label htmlFor="cta-input">Page Call to Action</label>
                      <Input
                        id="cta-input"
                        name="tag_cta"
                        type="text"
                        value={tagCTA}
                        onChange={e => setTagCTA(e.target.value)}
                      />
                    </FormGroup>
                    <Button
                      className="btn-inkBlue"
                      onClick={handleSubmit}
                      disabled={pagesState.isLoading}
                      style={{ display: 'flex' }}
                    >
                      {editPageSelected ? 'Update Page' : 'Create Page'} &nbsp;{' '}
                      {pagesState.isLoading && <Loader type="spinner" width={16} height={16} />}
                    </Button>
                  </CardBody>
                </Card>
              </Form>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default PagesDetail;
